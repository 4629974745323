// import {Sortable, Plugins, Draggable} from '@shopify/draggable'
import Sortable from 'sortablejs';

if (typeof window.livewire === 'undefined') {
    throw 'Livewire Sortable Plugin: window.livewire is undefined. Make sure @livewireScripts is placed above this script include'
}

window.livewire.directive('sortable-group', (el, directive, component) => {
    if (directive.modifiers.includes('item-group')) {
        // This will take care of new items added from Livewire during runtime.
        el.closest('[wire\\:sortable-group]').livewire_sortable.addContainer(el)
    }

    // Only fire the rest of this handler on the "root" directive.
    if (directive.modifiers.length > 0) return

    let options = {
        draggable: '[wire\\:sortable-group\\.item]',
        scrollable: {
            speed: 6,
            sensitivity: 12,
        }
    }

    if (el.querySelector('[wire\\:sortable-group\\.handle]')) {
        options.handle ='[wire\\:sortable-group\\.handle]'
    }

    const sortable = el.livewire_sortable = new Sortable([], options);

    sortable.on('sortable:stop', () => {
        setTimeout(() => {
            let groups = []

            el.querySelectorAll('[wire\\:sortable-group\\.item-group]').forEach((el, index) => {
                let items = []
                el.querySelectorAll('[wire\\:sortable-group\\.item]').forEach((el, index) => {
                    items.push({ order: index + 1, value: el.getAttribute('wire:sortable-group.item')})
                })

                groups.push({
                    order: index + 1,
                    value: el.getAttribute('wire:sortable-group.item-group'),
                    items: items,
                })
            })
            console.log(groups);
            component.call(directive.method, groups)
        }, 1)
    })
})

window.livewire.directive('sortable', (el, directive, component) => {
    // Only fire this handler on the "root" directive.
    if (directive.modifiers.length > 0) return

    let key = el.getAttribute('wire:sortable.key');
    let draggable = '[wire\\:sortable\\.item]';
    let handle = '[wire\\:sortable\\.handle]';

    if (key) {
        let keyAttr = '[wire\\:sortable\\.key="' + key + '"]';
        draggable += keyAttr;
        handle += keyAttr;
    }

    // let options = {
    //     draggable,
    //     mirror: {
    //         constrainDimensions: true,
    //     },
    //     scrollable: {
    //         speed: 100,
    //         sensitivity: 100,
    //     },
    //     swapAnimation: {
    //         duration: 200,
    //         easingFunction: 'ease-in-out',
    //     },
    //     plugins: [Draggable.Plugins.Scrollable, Plugins.ResizeMirror, Plugins.SwapAnimation]
    // };
    //
    // if (el.querySelector(handle)) {
    //     options.handle = handle
    // }

    // const sortable = new Sortable(el, options);
    const sortable = Sortable.create(el, {
        handle,
        draggable,
        dragoverBubble: true,
        removeCloneOnHide: true, // Remove the clone element when it is not showing, rather than just hiding it
        emptyInsertThreshold:  50,
        onEnd: function (evt) {
            setTimeout(() => {
                let items = []

                el.querySelectorAll(draggable).forEach((el, index) => {
                    items.push({ order: index + 1, value: el.getAttribute('wire:sortable.item')})
                })
                component.call(directive.method, items)
            }, 1)
        },
    });
    // sortable.on('sortable:start', () => {
    //
    // });
    //
    // sortable.on('sortable:stop', () => {
    //
    //     setTimeout(() => {
    //         let items = []
    //
    //         el.querySelectorAll(draggable).forEach((el, index) => {
    //             items.push({ order: index + 1, value: el.getAttribute('wire:sortable.item')})
    //         })
    //         component.call(directive.method, items)
    //     }, 1)
    // })
})
